$success: #67897b;
$danger: #d65846;
$h1-font-size: 2rem;
// $h3-font-size: 1.3em;
$navbar-color: #000;

// X-Small	None	<576px
// Small	sm	≥576px
// Medium	md	≥768px
// Large	lg	≥992px
// Extra large	xl	≥1200px
// Extra extra large	xxl	≥1400px

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Varela";
  src: url("./assets/fonts/VarelaRound-Regular.ttf") format("truetype");
  font-display: swap;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bs-body-font-size: 1rem;
  --bs-btn-font-size: 1rem;
  // font-size: var(--bs-body-font-size);
  --bs-font-sans-serif: Varela, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
    "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // font-family: var(--bs-font-sans-serif);
  // --bs-body-color: #fff;
  // color: var(--bs-body-color);
  --augusta-green: #67897b;
}

@include media-breakpoint-up(lg) {
  .row .width-auto {
    width: auto;
    flex: 1 0;
  }
}

.silhouette {
  background: url("./assets/img/silhouettes/silhouette.png") rgba(0, 0, 0, 0);
  background-position: center center;
  height: 200px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.silhouette-1 {
  background: url("./assets/img/silhouettes/silhouette-1.png") rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.silhouette-2 {
  background: url("./assets/img/silhouettes/silhouette-2.png") rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.silhouette-3 {
  background: url("./assets/img/silhouettes/silhouette-3.png") rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.silhouette-4 {
  background: url("./assets/img/silhouettes/silhouette-4.png") rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.silhouette-5 {
  background: url("./assets/img/silhouettes/silhouette-5.png") rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.silhouette-6,
.silhouette-7,
.silhouette-8,
.silhouette-9 {
  background: url("./assets/img/silhouettes/silhouette-6.png") rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.fDbOPw input {
  margin-bottom: 0;
}
.fDbOPw .clear-icon {
  margin: 0px 10px 0 0;
}

.table > :not(caption) > * > * {
  padding: 0.2rem 0.5rem;
  /* background-color: var(--bs-table-bg); */
  border-bottom-width: 0;
  /* box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); */
}

.color-white {
  color: #fff;
}

.error:focus-visible {
  outline: none !important;
}
.bold {
  font-weight: bolder;
}
.augusta {
  color: var(--augusta-green);
}
.augusta-bg {
  background-color: var(--augusta-green);
}
.white-bg {
  background-color: #fff;

  a,
  .btn-link {
    color: var(--augusta-green);
  }
}
.flex-1 {
  flex: 1;
}

.help {
  font-weight: bolder;
}

.font-big {
  font-size: 1.1em;
  line-height: 1.5em;
}
.small {
  font-size: 0.875em;
}
.smaller {
  font-size: 0.775em;
}

h1 {
  font-weight: bolder;
}
h4 {
  font-size: 1.2rem;
}

a,
.btn-link {
  color: var(--augusta-green);
  text-decoration: none;
}

.text-left {
  text-align: left;
}

@include media-breakpoint-up(md) {
  // @media (min-width: 576px) {
  .modal-dialog.wide {
    max-width: 1200px;
  }
}
// .btn-link {
//   color: #fff;
//   text-decoration: none;

//   &:hover,
//   &:active {
//     color: #fff !important;
//     text-decoration: none;
//   }
// }

.btn {
  border-radius: 4px;
  color: #fff;
}

.btn-outline {
  color: #000;
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--augusta-green);
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--augusta-green);
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.App {
  width: 100%;
  min-height: 100vh;
  background-image: url("./assets/img/background-mobile.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@include media-breakpoint-up(md) {
  .App {
    background-image: url("./assets/img/background-desktop.jpg");
  }
}

.navbar-golfamore {
  float: right;
  max-width: 500px;
  background: #fff;
  padding: 30px 40px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: left;
}
.navbar-text,
.navbar p {
  color: #000;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  color: #fff;
  width: 2em;
  height: 2em;
}

.navbar #logotype {
  width: 250px;
}

.navbar .btn-link {
  font-weight: bold;
  text-transform: uppercase;
}

.navbar .nav-link,
.navbar .navbar-text {
  color: #000;
  text-transform: uppercase;
  font-size: 0.875em;
  padding-bottom: 0;
}

.modal.wide .modal-dialog {
  max-width: 800px;
}
.modal .modal-body {
  padding: 4px;
  color: #000;
}
.modal .btn-link {
  color: #000;
}
.modal .modal-content {
  border-radius: 0px;
  color: #000;
  font-size: 1rem;
}
.modal img {
  width: 40%;
}

.card {
  text-align: center;
}
.card-header,
.card-body,
.card-footer {
  background-color: var(--augusta-green);
}

input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0.4rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &.error {
    border-color: $danger;
  }
}

.tournaments {
  .card-footer {
    min-height: 80px;
  }
}
.container-white {
  background-color: rgba(256, 256, 256, 0.9);
  padding: 0.3em;
  color: #000;
}

.leaderboard {
  border-collapse: separate;
  border-spacing: 0 0.4em;

  tr {
    background-color: #fff;
    color: #000;
  }

  td,
  th {
    vertical-align: middle;
    text-align: middle;
  }

  .btn-link {
    color: var(--augusta-green);
    font-weight: bold;
  }

  &.players {
    border-spacing: 0;

    tr.header {
      background-color: #413c3c;
      color: #fff;
    }
    td,
    th {
      border-top: 1pt solid #ccc;
    }

    tr:last-child td,
    tr:last-child th {
      border-bottom: 1pt solid #ccc;
    }
  }
}

.tournament-card {
  margin-bottom: 20px;
  color: #fff;

  a {
    color: #fff;
  }
}

.popover {
  width: 300px;
  padding: 20px;
  background-color: var(--augusta-green);
}

.to-par {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #000;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.1rem;

  @for $i from 1 through 10 {
    &.to-par-#{$i}:not(.Tot) {
      color: #fff;
      background: #000;
    }
  }

  &.to-par--1:not(.Tot) {
    color: #fff;
    background-color: #e03232;
  }
  &.to-par--2:not(.Tot) {
    color: #fff;
    background-color: #f38507;
  }
}

.loader-container {
  display: flex;
  align-items: center;
}

.loader {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 10px;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #e38b34 #e38b34;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 2px;
  height: 2px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

.loader.big {
  width: 28px;
  height: 28px;
}
.loader.big::after,
.loader.big::before {
  width: 20px;
  height: 20px;
}
.loader.big::before {
  width: 12px;
  height: 12px;
}
.loader.green {
  border-color: #67897b #67897b transparent transparent;
}
.loader.green::before {
  border-color: #67897b #67897b transparent transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.ellipsis p {
  cursor: pointer;
}
.ellipsis p.disabled {
  color: red;
  cursor: not-allowed;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Change opacity to adjust the darkness */
  z-index: 100; /* Make sure it's above other content */
  pointer-events: none; /* This allows clicks to pass through the element */
}

/* HighlightGuide.css */
.highlight-container {
  position: fixed;
  padding: 8px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 101;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.highlight-message {
  font-size: 16px;
  text-align: center;
}
